<template>
    <div>
        <div class="pl-2 pr-2">
            <Breadcrumb :breadcrumb="breadcrumbItems"/>
        </div>
        <div class="main">
            <div class="favorite">
                <div class="head">
                    <h3 class="title">{{ $t('lesson.favorites.favorite') }}</h3>
                </div>
                <div class="body">
                    <div class="switcher">
                        <label @click="handleFavoriteFilter('lesson')"
                               :class="`click-view ${filter.type=='lesson' && 'disabled'}`"
                        >
                            {{ $t('lesson.favorites.lesson') }}
                        </label>
                        <label @click="handleFavoriteFilter('teacher')"
                               :class="`click-view ${filter.type=='teacher' && 'disabled'}`"
                        >
                            {{ $t('lesson.favorites.teacher') }}
                        </label>
                    </div>

                    <div class="content-body">
                        <div class="teacher" v-if="filter.type=='teacher'">
                            <Teacher
                                :list="getFavoriteListTeacher.data"
                            />
                            <div class="text-center p-5" v-if="getFavoriteListTeacher.data.length === 0 && !loading">
                                <h5>{{$t("lesson.no_favorite_teacher")}}</h5>
                            </div>
                        </div>
                        <div class="lesson" v-else>
                            <Lesson
                                :list="getFavoriteListLesson.data"
                            />
                            <div class="text-center p-5" v-if="getFavoriteListLesson.data.length === 0 && !loading">
                                <h5>{{$t("lesson.no_favorite_lesson")}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="filter.type=='teacher'">
                    <Pagination
                        :length="getFavoriteListTeacher.meta.last_page"
                        v-model="filter.page"
                        @input="onPageChange"
                        v-if="getFavoriteListTeacher.meta.last_page > 1"
                    />
                </div>
                <div v-else>
                    <Pagination
                        :length="getFavoriteListLesson.meta.last_page"
                        v-model="filter.page"
                        @input="onPageChange"
                        v-if="getFavoriteListLesson.meta.last_page > 1"
                    />
                </div>
            </div>
        </div>
        <MetaTags 
            title="お気に入り｜ResMom相談online"
            description="リセマム相談オンラインのお気に入り一覧です。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Teacher from "./components/teacher";
import Lesson from "./components/lesson";
export default {
    components: {
        Lesson,
        Teacher,
    },
    data() {
        return {
            filter: {
                type: "lesson",
                page: 1,
            },
            loading: false,
            breadcrumbItems : [
                {
                    title: this.$t("menu.breadcrumb.my_page"),
                    link: "/my-page"
                },
                {
                    title: this.$t("menu.breadcrumb.favorite"),
                    link: "#"
                }
            ]
        };
    },
    watch: {
        $route: {
            deep: true,
            async handler() {
                this.filter = this.$router.getQuery(this.filter);
                this.$router.setQuery(this.filter);
                this.loading = true;
                await this.fetchFavorite(this.filter);
                this.loading = false;
            },
        },
    },
    async created() {
        this.filter = this.$router.getQuery(this.filter);
        if(!this.filter.type){
            this.filter = {
                ...this.filter,
                type: "lesson"
            }
        }
        this.loading = true;
        await this.fetchFavorite(this.filter);
        this.loading = false;
    },
    computed: {
        ...mapGetters({
            getFavoriteListLesson: "mypage/getFavoriteListLesson",
            getFavoriteListTeacher: "mypage/getFavoriteListTeacher",
            member: "getMember",
        }),
    },
    methods: {
        ...mapActions({
            fetchFavorite: "mypage/fetchMyPageFavoriteList"
        }),
        onPageChange(page) {
            this.filter = {
                ...this.filter,
                page,
            };
            this.$router.setQuery(this.filter);
        },
        handleFavoriteFilter(filterType) {
            this.filter.type = filterType;
            this.$router.setQuery(this.filter);
        },
    },
};
</script>
<style>
</style>